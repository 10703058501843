@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding: 0;
  border: none;
}
.searchBlock {
  display: flex;
  align-items: center;
  position: relative;
  & > svg {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    z-index: 1;
    width: 16px;
    height: 16px;
  }
  & > input {
    padding-left: 40px;
    border-radius: 100px;
    position: relative;
    z-index: 0;
  }
}

.list,
.regularList {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  /* max-height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  } */
}
.regularList {
  margin: 16px 0 0 0;
}

.item {
  padding: 2px 0;
  width: 100%;
  margin-bottom: 5px;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportSmall) {
    width: calc(100% / 2);
  }

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  &.colorItem {
    display: flex;
    align-items: center;
    & > label {
      & > img {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border: solid 2px var(--matterColorAnti);
        border-radius: 100px;
        padding: 1px;
      }
    }
    & > span {
      margin-left: 12px;
      & > label {
        margin: 0;
        padding: 0;
        & > span {
          margin: 0;
          padding: 0;
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}

.showMore {
  color: var(--matterColorAnti);

  &:hover {
    color: var(--matterColorDark);
    cursor: pointer;
    text-decoration: underline;
  }
}

.showLess {
  color: var(--matterColorDark);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--matterColorAnti);
  }
}

.activeColorItem {
  cursor: pointer;
  & > label {
    & > img {
      border: solid 2px var(--marketplaceColor) !important;
    }
  }
}

.optionHeading {
  margin: 0px 0px 10px;
  font-size: 16px;
  font-weight: 600;
}

.pinnedListSec {
  border: 1px solid var(--matterColorNegative);
  background-color: #f7f7f7;
  padding: 16px;
  border-radius: 10px;
  margin-top: 16px;
  & .pinnedHeader {
    font-size: 15px;
    font-weight: var(--fontWeightBold);
    color: var(--matterColorDark);
    margin: 0 0 10px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  & .pinnedList {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }
}
.clearBtn {
  composes: marketplaceH5FontStyles from global;
  margin: 0;
  padding: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  text-decoration: underline;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }
}
