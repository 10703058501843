@import '../../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
}

.label {
  composes: marketplaceH5FontStyles from global;
  font-weight: var(--fontWeightSemiBold);
}

.inputsWrapper {
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  text-align: center;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.maxPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  text-align: center;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.priceInSidebar {
  width: 72px;
}

.priceSeparator {
}

.sliderWrapper {
  display: flex;
  flex-wrap: wrap;

  & .pricePill {
    background-color: var(--matterColorLight);
    border: solid 1px var(--matterColorNegative);
    border-radius: 100px;
    padding: 6px 14px;
    font-size: 14px;
    line-height: 120%;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all ease 0.5s;

    &:hover {
      background-color: #faf8f8;
      border: solid 1px var(--matterColorDark);
      color: var(--matterColorDark);
      transition: all ease 0.5s;
    }

    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: #faf8f8;
      color: var(--matterColorDark);
      border: solid 1px var(--matterColorDark);
    }
  }
}

.buttonsWrapper {
  display: flex;
}

.clearButton {
  composes: marketplaceH4FontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  margin: 20px auto 0 0;
  padding: 0;
  text-decoration: underline;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  composes: marketplaceH4FontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  composes: marketplaceH4FontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
