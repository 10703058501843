@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportMedium) {
    border-bottom: 0;
  }
}

.filterHeader {
  line-height: 24px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
  /* margin-bottom: 14px; */

  &:hover {
    color: var(--matterColorDark);

    & * {
      color: var(--matterColorDark);
      stroke: var(--marketplaceColor);
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
    font-size: 20px;
  }
}

.label {
  color: var(--matterColorDark);
}

.labelSelected {
  color: var(--matterColorDark);
  margin-left: 5px;
}

.openSign {
  float: right;
}

.clearButton {
  composes: marketplaceH5FontStyles from global;
  margin: 20px auto 0 0;
  padding: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);
  text-decoration: underline;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }
}

.plain {
  width: 100%;
  display: none;
}

.isOpen {
  display: block;
  margin-top: 14px;
}
